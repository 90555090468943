<template>
  <CCard
    :color="selected ? 'succes' : 'light'"
    textColor=""
    style="cursor: pointer"
    :class="[
      selected ? 'border-info' : 'light',
      item.quantity_for_sale > 0 ? 'border-success' : 'light',
    ]"
  >
    <CCardBody class="w-100 p-0">
      <SCardProductItems
        class="p-3 overflow-hidden"
        :item="item.product"
        style="cursor: pointer; height: auto"
      >
        <template #append-content>
          <CRow>
            <div class="d-flex">
              <TMessage content="Supplier price">
                <template #suffix>
                  ({{ $t("Tax included") }})
                </template> </TMessage
              >:
              <TMessageMoney
                :amount="+item.pivot.price"
                :currency="currency"
                class="ml-2"
              />
            </div>
          </CRow>
        </template>
      </SCardProductItems>
      <TTableAsForm
        :data="item"
        :fields="quantityFields"
        :splitLeft="7"
        :addRowClasses="['pb-2']"
        class="px-2 pb-1"
      >
        <template #quantity>
          <TMessageNumber :value="item.output" />
        </template>
        <template #quantity_for_sale>
          <TInputNumber :value.sync="item.quantity_for_sale" />
        </template>
        <template #tax_percent>
          <TInputNumber
            :value.sync="item.tax_percent"
            :maskOptions="{ integerLimit: 2 }"
          />
        </template>
      </TTableAsForm>
    </CCardBody>
  </CCard>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
      required: false,
      default: false,
    },
    currency: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      quantityFields: [
        { key: "quantity", label: "Quantity to buy" },
        { key: "tax_percent", label: "Tax percent" },
        { key: "quantity_for_sale", label: "Purchase quantity" },
      ],
    };
  },
};
</script>
