<template>
  <TSelect
    :label="label"
    class="m-0"
    addLabelClasses="font-weight-bold"
    :options="list"
    :value="valueSelected"
    :selectOptions="{
      internalSearch: true,
      preserveSearch: true,
      ...customOptionLabel,
      ...selectOptions,
    }"
    :normalizer="{ label: 'purchase_id', value: 'purchase_id' }"
    :placeholder="placeholder"
    @select="select"
  />
</template>
<script>
export default {
  props: {
    value: {
      type: [Number, String],
      required: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      default: "Select",
    },
    noCustomLabel: {
      type: Boolean,
      default: false,
    },
    selectOptions: {
      type: Object,
      require: false,
    },
    list: {
      type: Array,
      require: false,
    },
  },
  data() {
    return {
      valueSelected: null,
    };
  },
  watch: {
    value(value) {
      if (!value) this.valueSelected = null;
    },
  },
  computed: {
    customOptionLabel() {
      return !this.noCustomLabel ? { "custom-label": this.customLabel } : {};
    },
  },
  methods: {
    select(value) {
      this.valueSelected = value;
      this.$emit("update:value", value.purchase_id);
      this.$emit("change", value);
    },
    customLabel({ purchase_id, remaining_distributed_quantity }) {
      return `${purchase_id} – ${this.$t(
        "Inventory number"
      )}: ${remaining_distributed_quantity}`;
    },
  },
};
</script>
