var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.listRender.length)?_c('CCard',[_c('CCardBody',[_c('CRow',[_c('CCol',{staticClass:"rounded p-2 w-100",attrs:{"col":"12","sm":"12","md":"3"}},[_c('CWidgetBrand',{staticClass:"w-100 border-primary",attrs:{"color":"primary"},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',{staticClass:"p-2 overflow-auto"},[_c('div',{staticClass:"d-flex"},[_c('CIcon',{staticClass:"my-auto mr-2",staticStyle:{"width":"15px","min-width":"15px"},attrs:{"name":"cil-envelope-closed"}}),_c('TMessage',{attrs:{"content":_vm.supplier.email,"noTranslate":""}})],1),_c('div',{staticClass:"d-flex mt-2"},[_c('CIcon',{staticClass:"my-auto mr-2",staticStyle:{"width":"15px","min-width":"15px"},attrs:{"name":"cil-location-pin"}}),_c('TMessage',{attrs:{"content":_vm.supplier.address,"noTranslate":""}})],1)])]},proxy:true}],null,false,1939541528)},[_c('TMessage',{staticClass:"pt-3 pb-2 px-3 m-0",attrs:{"content":_vm.supplier.name,"noTranslate":"","size":"h4"}})],1),_c('div',{staticClass:"float-right"})],1),(_vm.listRender)?_c('CCol',{staticClass:"d-flex flex-wrap align-content-start",attrs:{"col":"12","sm":"12","md":"9"}},[_c('div',{staticClass:"d-flex w-100 mb-3"},[_c('TMessage',{staticClass:"my-auto",attrs:{"content":"Selected","size":"h5"},scopedSlots:_vm._u([{key:"suffix",fn:function(){return [_vm._v(" "+_vm._s(_vm.listRender.filter((x) => x.checked == true).length)+" / "+_vm._s(_vm.listRender.length)+" ")]},proxy:true}],null,false,2614411735)}),_c('TButton',{staticClass:"d-flex ml-auto",attrs:{"content":"Next step","icon":"cis-chevron-double-right","color":"primary","options":{
              disabled: _vm.listRender.every((x) => !x.checked),
            }},on:{"click":function($event){return _vm.selectItem(_vm.list)}}})],1),_vm._l((_vm.listRender),function(product,index){return _c('div',{key:`${product.product_id}-${index}`,staticClass:"w-100 mb-3 d-flex align-items-start"},[(product.orderItems.length)?_c('div',{staticClass:"mr-1"},[_c('TInputCheckbox',{staticClass:"mb-3 ml-1",staticStyle:{"transform":"scale(1.3)"},attrs:{"checked":product.checked},on:{"change":function($event){product.checked = !product.checked}}})],1):_vm._e(),(product.orderItems.length)?_c('div',{class:[
              'product-item border-primary rounded p-2 w-100',
              {
                'border-danger': _vm.checkLowestPrice(product),
              },
            ],staticStyle:{"height":"auto"}},[(_vm.checkLowestPrice(product))?_c('CBadge',{staticClass:"lowest-price-tag p-1 my-auto",attrs:{"color":"danger"}},[_c('TMessage',{attrs:{"content":"Lowest price"}})],1):_vm._e(),_c('SCardProductItems',{staticClass:"w-100",staticStyle:{"cursor":"pointer","height":"auto"},attrs:{"widthAuto":"","item":product.product},nativeOn:{"click":function($event){product.checked = !product.checked}},scopedSlots:_vm._u([{key:"append-content",fn:function(){return [_c('CRow',{class:[
                    'justify-content-between p-1 px-2 rounded text-white bg-primary',
                    {
                      'bg-danger': _vm.checkLowestPrice(product),
                    },
                  ]},[_c('CCol',{staticClass:"p-0",attrs:{"col":"12","md":"6","xl":"4"}},[_c('TMessage',{attrs:{"content":"Quantity to buy"},scopedSlots:_vm._u([{key:"suffix",fn:function(){return [_c('span',[_vm._v(": "+_vm._s(product.output))])]},proxy:true}],null,true)})],1),_c('CCol',{staticClass:"p-0",attrs:{"col":"12","md":"6","xl":"4"}},[_c('div',{staticClass:"d-flex"},[_c('TMessage',{attrs:{"content":"Price"}}),_vm._v(": "),_c('TMessageMoney',{staticClass:"ml-2",attrs:{"amount":+product.pivot.price,"currency":"JPY"}})],1)]),_c('CCol',{staticClass:"p-0",attrs:{"col":"12","md":"6","xl":"4"}},[_c('div',{staticClass:"d-flex"},[_c('TMessage',{attrs:{"content":"Amount","truncate":1}}),_vm._v(": "),_c('TMessageMoney',{staticClass:"ml-2",attrs:{"amount":+product.pivot.price * product.output,"currency":"JPY"}})],1)])],1)]},proxy:true}],null,true)}),(product.orderItems.length)?_c('TMessage',{staticClass:"mt-1",attrs:{"content":"Order list","bold":""}}):_vm._e(),_vm._l((product.orderItems),function(order){return _c('div',{key:order.id,staticClass:"d-flex pb-1 flex-wrap"},[(order.order)?_c('SMessageShipmentMethod',{attrs:{"id":order.order.shipment_method_id,"iconOnly":""}}):_vm._e(),_c('TMessage',{staticClass:"text-info",attrs:{"content":order.order_id,"noTranslate":"","bold":""}}),_c('CBadge',{staticClass:"my-auto mx-1",attrs:{"color":order.distributed_quantity ? 'info' : 'success'}},[_c('TMessage',{attrs:{"content":order.distributed_quantity
                      ? 'Additional purchase order'
                      : 'New purchase order'}})],1),_c('TMessage',{attrs:{"content":order.order.customer
                    ? order.order.customer.name
                    : order.order.customer_id,"bold":"","noTranslate":""}}),_c('span',{staticClass:"mx-1"},[_vm._v("-")]),(order.remaining_distributed_quantity)?_c('TMessage',{attrs:{"content":"Quantity","bold":"","addClasses":['d-flex']},scopedSlots:_vm._u([{key:"suffix",fn:function(){return [_vm._v(": "),_c('CBadge',{staticClass:"ml-1 my-auto",attrs:{"color":"info"}},[_c('TMessageNumber',{attrs:{"value":order.remaining_distributed_quantity}})],1)]},proxy:true}],null,true)}):_vm._e(),_c('span',{staticClass:"mx-1"},[_vm._v("-")]),_c('TMessage',{attrs:{"content":"Desired delivery","bold":"","addClasses":['d-flex']},scopedSlots:_vm._u([{key:"suffix",fn:function(){return [_vm._v(": "),_c('CBadge',{staticClass:"ml-1 my-auto",attrs:{"color":"danger"}},[_c('TMessageDateTime',{attrs:{"content":order.expected_delivery,"dateOnly":""}})],1)]},proxy:true}],null,true)})],1)})],2):_vm._e()])})],2):_vm._e()],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }