<template>
  <CCard v-if="listRender.length">
    <CCardBody>
      <CRow>
        <CCol col="12" sm="12" md="3" class="rounded p-2 w-100">
          <CWidgetBrand color="primary" class="w-100 border-primary">
            <TMessage
              :content="supplier.name"
              noTranslate
              size="h4"
              class="pt-3 pb-2 px-3 m-0"
            />
            <template #body>
              <div class="p-2 overflow-auto">
                <div class="d-flex">
                  <CIcon
                    name="cil-envelope-closed"
                    class="my-auto mr-2"
                    style="width: 15px; min-width: 15px"
                  />
                  <TMessage :content="supplier.email" noTranslate />
                </div>
                <div class="d-flex mt-2">
                  <CIcon
                    name="cil-location-pin"
                    class="my-auto mr-2"
                    style="width: 15px; min-width: 15px"
                  />
                  <TMessage :content="supplier.address" noTranslate />
                </div>
              </div>
            </template>
          </CWidgetBrand>
          <div class="float-right"></div>
        </CCol>
        <CCol
          col="12"
          sm="12"
          md="9"
          class="d-flex flex-wrap align-content-start"
          v-if="listRender"
        >
          <div class="d-flex w-100 mb-3">
            <TMessage class="my-auto" content="Selected" size="h5">
              <template #suffix>
                {{ listRender.filter((x) => x.checked == true).length }}
                / {{ listRender.length }}
              </template>
            </TMessage>
            <TButton
              content="Next step"
              icon="cis-chevron-double-right"
              color="primary"
              class="d-flex ml-auto"
              :options="{
                disabled: listRender.every((x) => !x.checked),
              }"
              @click="selectItem(list)"
            />
          </div>
          <div
            v-for="(product, index) in listRender"
            :key="`${product.product_id}-${index}`"
            class="w-100 mb-3 d-flex align-items-start"
          >
            <div class="mr-1" v-if="product.orderItems.length">
              <TInputCheckbox
                :checked="product.checked"
                @change="product.checked = !product.checked"
                class="mb-3 ml-1"
                style="transform: scale(1.3)"
              />
              <!-- <CLink
                @click="fetchOrder(product.product_id)"
                :title="$t('View order')"
                :disabled="fetching"
              >
                <CIcon name="cil-reload" size="custom" width="20px" />
              </CLink> -->
            </div>
            <div
              v-if="product.orderItems.length"
              :class="[
                'product-item border-primary rounded p-2 w-100',
                {
                  'border-danger': checkLowestPrice(product),
                },
              ]"
              style="height: auto"
            >
              <CBadge
                v-if="checkLowestPrice(product)"
                color="danger"
                class="lowest-price-tag p-1 my-auto"
              >
                <TMessage content="Lowest price" />
              </CBadge>
              <SCardProductItems
                class="w-100"
                widthAuto
                :item="product.product"
                @click.native="product.checked = !product.checked"
                style="cursor: pointer; height: auto"
              >
                <template #append-content>
                  <CRow
                    :class="[
                      'justify-content-between p-1 px-2 rounded text-white bg-primary',
                      {
                        'bg-danger': checkLowestPrice(product),
                      },
                    ]"
                  >
                    <CCol col="12" md="6" xl="4" class="p-0">
                      <TMessage content="Quantity to buy">
                        <template #suffix>
                          <span>: {{ product.output }}</span>
                        </template>
                      </TMessage>
                    </CCol>
                    <CCol col="12" md="6" xl="4" class="p-0">
                      <div class="d-flex">
                        <TMessage content="Price"> </TMessage>:
                        <TMessageMoney
                          :amount="+product.pivot.price"
                          class="ml-2"
                          currency="JPY"
                        />
                      </div>
                    </CCol>
                    <CCol col="12" md="6" xl="4" class="p-0">
                      <div class="d-flex">
                        <TMessage content="Amount" :truncate="1"> </TMessage>:
                        <TMessageMoney
                          :amount="+product.pivot.price * product.output"
                          class="ml-2"
                          currency="JPY"
                        />
                      </div>
                    </CCol>
                  </CRow>
                </template>
              </SCardProductItems>
              <TMessage
                v-if="product.orderItems.length"
                content="Order list"
                bold
                class="mt-1"
              />
              <div
                v-for="order in product.orderItems"
                :key="order.id"
                class="d-flex pb-1 flex-wrap"
              >
                <SMessageShipmentMethod
                  v-if="order.order"
                  :id="order.order.shipment_method_id"
                  iconOnly
                />
                <TMessage
                  :content="order.order_id"
                  noTranslate
                  bold
                  class="text-info"
                />
                <CBadge
                  class="my-auto mx-1"
                  :color="order.distributed_quantity ? 'info' : 'success'"
                >
                  <TMessage
                    :content="
                      order.distributed_quantity
                        ? 'Additional purchase order'
                        : 'New purchase order'
                    "
                  />
                </CBadge>
                <TMessage
                  :content="
                    order.order.customer
                      ? order.order.customer.name
                      : order.order.customer_id
                  "
                  bold
                  noTranslate
                />
                <span class="mx-1">-</span>
                <TMessage
                  v-if="order.remaining_distributed_quantity"
                  content="Quantity"
                  bold
                  :addClasses="['d-flex']"
                >
                  <template #suffix
                    >:
                    <CBadge color="info" class="ml-1 my-auto">
                      <TMessageNumber
                        :value="order.remaining_distributed_quantity"
                      />
                    </CBadge>
                  </template>
                </TMessage>
                <span class="mx-1">-</span>
                <TMessage
                  content="Desired delivery"
                  bold
                  :addClasses="['d-flex']"
                >
                  <template #suffix
                    >:
                    <CBadge color="danger" class="ml-1 my-auto">
                      <TMessageDateTime
                        :content="order.expected_delivery"
                        dateOnly
                      />
                    </CBadge>
                  </template>
                </TMessage>
              </div>
            </div>
          </div>
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
export default {
  props: {
    supplier: {
      type: Object,
      required: true,
    },
    lowestPrice: {
      type: Boolean,
      required: false,
      default: false,
    },
    expectedDelivery: {
      type: Object,
      required: false,
    },
    productIdFilter: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      list: {},
      fetching: false,
    };
  },
  watch: {
    supplier: {
      immediate: true,
      handler(data) {
        if (data)
          this.list = {
            ...data,
            products_to_purchase: data.products_to_purchase.map((y) => {
              return {
                ...y,
                orderItems: y.order_items.filter((x) =>
                  this.availableForDistribution(x.order.status_id)
                ),
                checked: false,
              };
            }),
            products_to_purchaseC: data.products_to_purchase.map((y) => {
              return {
                ...y,
                orderItems: y.order_items.filter((x) =>
                  this.availableForDistribution(x.order.status_id)
                ),
                checked: false,
              };
            }),
          };
      },
    },
    expectedDelivery: {
      immediate: true,
      handler() {
        this.list = {
          ...this.list,
          products_to_purchase: this.list.products_to_purchase.map((y) => {
            return {
              ...y,
              orderItems: this.getOrderItems(y.order_items),
              checked: false,
            };
          }),
        };
      },
    },
    productIdFilter: {
      immediate: true,
      handler(value) {
        this.list = {
          ...this.list,
          products_to_purchase: this.list.products_to_purchaseC
            .filter((x) => {
              if (value) return x.product_id == value;
              else return x;
            })
            .map((y) => {
              return {
                ...y,
                orderItems: this.getOrderItems(y.order_items),
                checked: false,
              };
            }),
        };
      },
    },
  },
  computed: {
    listRender() {
      return (
        this.list.products_to_purchase.filter((x) => x.orderItems.length) || []
      );
    },
  },
  methods: {
    selectItem(item) {
      const listProduct = item.products_to_purchase.filter(
        (x) => x.checked == true
      );
      const data = listProduct.map((x) => {
        return {
          ...x,
          quantity_for_sale: 0,
          quantity_in_stock: 0,
          tax_percent: x.product.tax_percent || 0,
        };
      });

      this.$emit("updated", item, data);
      this.$router.push({ query: { tab: 1 } });
    },
    checkLowestPrice(product) {
      return (
        product.lowest_price_suppliers.some((x) => x.id == this.supplier.id) &&
        this.lowestPrice
      );
    },
    availableForDistribution(status) {
      return ![
        "Cancelled",
        "Finish",
        "OutOfStock",
        "Pending",
        // "Quoted",
      ].includes(status);
    },
    getOrderItems(list) {
      if (!this.lodash.isEmpty(this.lodash.pickBy(this.expectedDelivery))) {
        return list.filter(
          (x) =>
            this.expectedDelivery.start <= x.expected_delivery &&
            x.expected_delivery <= this.expectedDelivery.end &&
            this.availableForDistribution(x.order.status_id)
        );
      } else {
        return (
          list.filter((x) =>
            this.availableForDistribution(x.order.status_id)
          ) || []
        );
      }
    },
  },
};
</script>

<style scoped>
.product-item >>> .lowest-price-tag {
  position: absolute;
  top: -15px;
}
.product-item {
  position: relative;
}
</style>
