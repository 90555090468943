<template>
  <CCard
    :color="selected ? '' : 'light'"
    style="cursor: pointer"
    :borderColor="getColor"
  >
    <CCardBody class="p-2">
      <CRow>
        <CCol col="6">
          <div class="d-flex">
            <SMessageShipmentMethod
              :id="order.order.shipment_method_id"
              iconOnly
            />
            <TMessage
              :content="order.order_id"
              noTranslate
              bold
              class="text-info"
            />
            <CBadge
              class="my-auto ml-1"
              :color="order.distributed_quantity ? 'info' : 'success'"
            >
              <TMessage
                :content="
                  order.distributed_quantity
                    ? 'Additional purchase order'
                    : 'New purchase order'
                "
              />
            </CBadge>
          </div>
          <TMessage content="Desired delivery" :addClasses="['d-flex']">
            <template #suffix
              >:
              <TMessageDateTime
                class="ml-1 font-weight-bold"
                :content="order.expected_delivery"
                dateOnly
              />
            </template>
          </TMessage>
          <TMessage content="Customer" v-if="order.customer">
            <template #suffix
              >: <b>{{ order.customer.name }}</b>
            </template>
          </TMessage>
          <TMessage
            content="Currency"
            v-if="order.order.purchase_cost_currency_id"
          >
            <template #suffix
              >:
              <SMessageCurrency :id="order.order.purchase_cost_currency_id" />
            </template>
          </TMessage>
          <TMessage content="Note" v-if="order.order.note">
            <template #suffix>: {{ order.order.note }}</template>
          </TMessage>
        </CCol>
        <CCol>
          <TMessage
            v-if="order.remaining_distributed_quantity"
            content="Quantity to buy"
            bold
          >
            <template #suffix
              >:
              <span class="text-danger">{{
                order.remaining_distributed_quantity
              }}</span></template
            >
          </TMessage>
          <TMessage
            content="Order quantity from supplier"
            bold
            v-if="order.quantity_from_supplier"
          >
            <template #suffix
              >:
              <span class="text-danger">{{
                order.quantity_from_supplier
              }}</span></template
            >
          </TMessage>
          <TMessage
            content="Quantity from inventory"
            bold
            v-if="order.quantity_from_inventory"
          >
            <template #suffix
              >:
              <span class="text-danger">{{
                order.quantity_from_inventory
              }}</span></template
            >
          </TMessage>
          <TMessage
            content="Supply sale price"
            bold
            v-if="order.supply_sale_price"
            :addClasses="['d-flex']"
          >
            <template #suffix
              >:
              <TMessageMoney
                class="ml-1"
                :amount="order.supply_sale_price"
                :currency="currency_id"
              />
            </template>
          </TMessage>
        </CCol>
      </CRow>
      <div v-if="selected">
        <hr />
        <TMessage
          content="Order from supplier"
          bold
          class="mt-3 h5 text-danger"
        />
        <CRow>
          <CCol col="6">
            <TMessage content="Order quantity" bold>
              <template #suffix
                >:
                <span class="text-dange font-weight-bold"
                  >{{ order.quantity }}
                </span></template
              >
            </TMessage>
            <TMessage content="Quantity purchased" bold>
              <template #suffix
                >:
                <span class="text-dange font-weight-bold"
                  >{{ order.distributed_quantity }}
                </span></template
              >
            </TMessage>
          </CCol>
        </CRow>
        <CRow class="mb-2">
          <CCol col="6">
            <TInputNumber
              label="Order quantity from supplier"
              :value.sync="quantity_from_supplier"
              @update:value="quantity_from_supplier = $event"
            />
          </CCol>
          <CCol col="6">
            <TInputMoney
              label="Supply sale price"
              :value.sync="supply_sale_price"
              @update:value="supply_sale_price = $event"
              :currency="currency_id"
            />
          </CCol>
        </CRow>
        <TInputCheckbox
          :checked="haveDistribute"
          @change="haveDistribute = $event"
          label="Distributing goods from inventory"
        />
        <div v-if="haveDistribute">
          <CRow>
            <CCol xl="6" lg="12" class="mb-3">
              <SelectPurchaseItem
                label="Purchase order"
                :productId="order.product_id"
                :list="listC"
                :value.sync="distributeItem.purchase_id"
                @change="selectPurchase"
              />
            </CCol>
            <CCol xl="3" lg="12" class="mb-2">
              <TInputNumber
                label="Quantity"
                :value.sync="distributeItem.quantity"
                @update:value="distributeItem.quantity = $event"
              />
            </CCol>
            <CCol class="mt-auto mb-3">
              <TButton
                content="Add"
                class="w-100"
                @click="createDistributeItem"
                :options="{ disabled: disabledAddDistributeItem }"
              />
            </CCol>
          </CRow>
          <TTableAdvance
            v-if="distributeList.length > 0"
            :items="distributeList"
            :fields="distributeFields"
            store=""
            resource=""
            noFilter
            noPaginate
            noClickRow
          >
            <template #append-actions="{ item }">
              <CCol class="px-1 ml-1" col="12">
                <TButtonRemove @click="remove(item.purchase_id)" noConfirm />
              </CCol>
            </template>
            <template #purchase_id="{ item }">
              <td>
                <TMessage :content="item.purchase_id" noTranslate />
              </td>
            </template>
            <template #price="{ item }">
              <td>
                <TInputMoney
                  v-if="!item.distributed.length"
                  @update:value="item.price = $event"
                  :value="item.price"
                  currency="JPY"
                />
                <TMessage v-else content="Distributed" />
              </td>
            </template>
            <template #quantity="{ item }">
              <td>
                <TMessageNumber
                  @change="item.quantity = $event"
                  :value="item.quantity"
                />
              </td>
            </template>
          </TTableAdvance>
        </div>
        <TMessage
          content="Total quantity"
          bold
          alignment="right"
          size="h5"
          class="mt-3"
        >
          <template #suffix
            >:
            <span>{{ quantity_expected }}</span>
          </template>
        </TMessage>
        <TMessage
          v-if="disabledBtn"
          content="The quantity cannot be more than"
          bold
          class="text-danger mb-3"
          alignment="right"
        >
          <template #suffix>
            {{ order.remaining_distributed_quantity }}
          </template>
        </TMessage>
        <TButton
          :options="{ disabled: disabledBtn }"
          content="Save"
          :color="unSaved ? 'warning' : 'primary'"
          icon="cil-check"
          @click="save"
          class="w-100"
        />
      </div> </CCardBody
  ></CCard>
</template>

<script>
import SelectPurchaseItem from "./SelectPurchaseItem.vue";
export default {
  components: { SelectPurchaseItem },
  props: {
    order: {
      type: Object,
      required: true,
    },
    product_id: {
      type: String,
      required: true,
    },
    selected: {
      type: Boolean,
      required: false,
      default: false,
    },
    list: {
      type: Array,
      required: true,
    },
    product: {
      type: Object,
      required: false,
    },
    currency_id: {
      type: String,
      required: false,
      default: "",
    },
  },
  defaultDistributeItem: {
    purchase_id: "",
    quantity: 0,
    price: 0,
  },
  data() {
    return {
      distributeList: [],
      distributeItem: this.defaultItem(),
      quantity_from_supplier: 0,
      supply_sale_price: 0,
      distributeFields: [
        { key: "_", _style: "width: 40px" },
        { key: "purchase_id", label: "Purchase order" },
        { key: "price", label: "Supply sale price" },
        {
          key: "quantity",
          label: "Quantity",
          _style: "width: 30%; min-width: 30%",
        },
      ],
      showModal: false,
      haveDistribute: false,
      maxQuantity: 0,
    };
  },
  destroyed() {
    if (this.distributeList.length > this.order.distribute_inventory.length) {
      this.$emit("update:remaining-quantity", this.distributeList, {
        increase: false,
        product_id: this.product_id,
      });
    }
  },
  watch: {
    selected(value) {
      if (value) {
        this.distributeItem = this.defaultItem();
        if (this.distributeList.length) this.haveDistribute = true;
        if (!this.quantity_from_supplier && !this.order.quantity_from_supplier)
          this.quantity_from_supplier =
            this.order.remaining_distributed_quantity;
        if (!this.supply_sale_price && !this.order.supply_sale_price)
          this.supply_sale_price = +this.product.pivot.price;
      }
    },
    order: {
      immediate: true,
      handler() {
        this.distributeList = this.order.distribute_inventory;
      },
    },
    haveDistribute(value) {
      if (!value) {
        this.order.distribute_inventory = [];
        this.order.quantity_from_inventory = 0;
        const list = this.lodash.cloneDeep(this.distributeList);
        list.forEach((x) => {
          this.remove(x.purchase_id);
        });
      }
    },
  },
  computed: {
    listC: {
      cache: false,
      get() {
        return this.list.map((x) => {
          return {
            ...x,
            $isDisabled: x?.remaining_distributed_quantity <= 0 ? true : false,
          };
        });
      },
    },
    totalQuantity() {
      const quantity_from_inventory = this.lodash.sumBy(
        this.distributeList,
        "quantity"
      );
      const quantity_actual =
        this.order.quantity_from_supplier + quantity_from_inventory;
      const quantity_expected =
        this.quantity_from_supplier + quantity_from_inventory;
      return quantity_expected > quantity_actual
        ? quantity_expected
        : quantity_actual;
    },
    quantity_from_inventory() {
      return this.lodash.sumBy(this.distributeList, "quantity");
    },
    quantity_expected() {
      return this.quantity_from_supplier + this.quantity_from_inventory;
    },
    disabledBtn() {
      return this.quantity_expected > this.order.remaining_distributed_quantity;
    },
    disabledAddDistributeItem() {
      return (
        this.maxQuantity < this.distributeItem.quantity ||
        !this.distributeItem.purchase_id ||
        this.distributeItem.quantity == 0
      );
    },
    unSaved() {
      return (
        !this.lodash.isEqual(
          this.order.distribute_inventory,
          this.distributeList
        ) ||
        (this.supply_sale_price != this.order.supply_sale_price &&
          this.supply_sale_price >= 0) ||
        (this.quantity_from_supplier != this.order.quantity_from_supplier &&
          this.quantity_from_supplier >= 0) ||
        this.lodash.sumBy(this.distributeList, "quantity") !=
          this.order.quantity_from_inventory
      );
    },
    getColor() {
      if (!this.order.quantity_from_supplier) return "light";
      if (this.unSaved) return "warning";
      if (this.selected) return "info";
      else return "success";
    },
  },
  methods: {
    save() {
      const data = {
        quantity_from_supplier: this.quantity_from_supplier,
        supply_sale_price: this.supply_sale_price,
        order_id: this.order.order_id,
        quantity_from_inventory: this.lodash.sumBy(
          this.distributeList,
          "quantity"
        ),
        distribute_inventory: this.distributeList,

        distributed: this.lodash.flattenDeep(
          this.distributeList.map((x) => x.distributed)
        ),
      };
      this.$emit("save-item", data);
    },
    createDistributeItem() {
      if (!this.disabled) {
        const checkExist = this.distributeList.find(
          (x) => x.purchase_id == this.distributeItem.purchase_id
        );
        if (!checkExist) {
          const item = {
            ...this.distributeItem,
            price: this.distributeItem.price * this.distributeItem.quantity,
          };
          this.distributeList = [...this.distributeList, item];
          this.$emit("update:remaining-quantity", [this.distributeItem], {
            increase: true,
            product_id: this.product_id,
          });
        }
        this.distributeItem = this.defaultItem();
      }
    },
    selectPurchase(data) {
      const item = {
        purchase_id: data.purchase_id,
        purchase_item_id: data.id,
        distributed: data.distributions,
        quantity: 0,
        price: data.price_with_tax,
      };
      this.distributeItem = item;
      this.maxQuantity = data.remaining_distributed_quantity;
    },
    remove(id) {
      const index = this.distributeList.findIndex((x) => x.purchase_id == id);
      const checkExist = this.order.distribute_inventory.find(
        (x) => x.purchase_id == id
      );
      if (index >= 0 && checkExist) {
        this.order.quantity_from_inventory -=
          this.distributeList[index]?.quantity;
      }
      if (index >= 0) {
        this.$emit("update:remaining-quantity", [this.distributeList[index]], {
          increase: false,
          product_id: this.product_id,
        });
        this.distributeList.splice(index, 1);
      }
      this.distributeItem = this.defaultItem();
    },
    defaultItem() {
      return { ...this.$options.defaultDistributeItem };
    },
  },
};
</script>
