<template>
  <TTabs
    :tabs="tabs"
    @change-tab="$router.push({ query: { tab: $event } })"
    :activeTab="$route.query.tab"
    :keepAlive="false"
  >
    <template #supplier>
      <div>
        <CCard>
          <CCardBody>
            <CRow>
              <CCol col="12" md="5">
                <TMessage content="Product" bold class="mb-2" />
                <SSelectProduct
                  :id.sync="product_id"
                  @update:id="selectProduct"
                  class="w-100 mb-3"
                  :showItem="false"
                  placeholder="Search"
                  @input="input"
                />
              </CCol>
              <CCol col="12" md="4">
                <TInputDateTimeRange
                  label="Expected delivery"
                  :value.sync="dateRange"
                  class="mb-3"
                  size="lg"
                />
              </CCol>
              <CCol col="12" md="2">
                <TMessage content="Lowest price" bold class="mb-2" />
                <TSwitch
                  :checked="lowestPrice"
                  @change="lowestPrice = !lowestPrice"
                  class="mt-2"
                  size="lg"
                />
              </CCol>
              <CCol
                md="1"
                class="d-flex mt-auto ml-auto mb-3 justify-content-end"
              >
                <TButtonClear @click="clearFilter" variant="outline" />
              </CCol>
            </CRow>
            <!-- <div class="float-right mt-3">
              <TButtonClear @click="clearFilter" variant="outline" />
             <SButtonSearchFilter
                class="ml-2"
                :disabled="loading"
                @click="search"
              />
            </div> -->
          </CCardBody>
        </CCard>
      </div>
      <div>
        <div
          v-if="loading"
          class="d-flex w-100 mt-5"
          style="position: relative"
        >
          <CElementCover :opacity="0.8">
            <div class="d-flex">
              <TMessage content="Loading" class="mr-2 h3" />
              <CSpinner size="5xl" color="success" />
            </div>
          </CElementCover>
        </div>
        <TMessageNoData v-if="!list.length && !loading" />
        <div v-if="list.length && !loading">
          <ProductSupplier
            v-for="supplier in list"
            :key="supplier.id"
            :supplier="supplier"
            @updated="updated"
            :lowestPrice="lowestPrice"
            :expectedDelivery="dateRange"
            :productIdFilter="product_id"
          />
          <CCol v-show="paginate.last > 1 && list.length" col="12" class="mt-2">
            <TPagination store="product.suppliers" />
          </CCol>
        </div>
      </div>
    </template>
    <template #create-purchases>
      <CreatePurchase
        v-if="supplier.id"
        :key="createPurchaseKey"
        :listToBuy="data"
        :expectedDelivery="dateRange"
        :supplier="supplier"
      />
    </template>
  </TTabs>
</template>
<script>
import { mapGetters } from "vuex";
import CreatePurchase from "./CreatePurchase.vue";
import ProductSupplier from "./ProductSupplier.vue";
export default {
  components: { CreatePurchase, ProductSupplier },
  data() {
    return {
      tabs: [
        { key: "supplier", name: "List to buy", icon: "cis-list-rich" },
        {
          key: "create-purchases",
          name: "Create purchase",
          icon: "cisLibraryAdd",
          disabled: true,
        },
      ],
      product_id: "",
      lowestPrice: false,
      createPurchaseKey: "create-purchase",
      data: [],
      supplier: {},
      listToBuy: [],
      dateRange: {
        start: null,
        end: null,
      },
    };
  },
  created() {
    this.search();
  },
  destroyed() {
    this.$store.commit("product.suppliers.reset-query");
  },
  computed: {
    ...mapGetters({
      list: "product.suppliers.list",
      paginate: "product.suppliers.paginate",
      loading: "product.suppliers.loading",
    }),
  },
  methods: {
    updated(supplier, data) {
      this.supplier = supplier;
      this.data = data;
    },
    search() {
      this.$store.dispatch(
        "product.suppliers.apply-query",
        this.lodash.pickBy({
          append: "productsToPurchase.order_items",
          include:
            "productsToPurchase.lowestPriceSuppliers,productsToPurchase.product",
          "filter_between[productStatistics.inventory]": "infinity,0",
          // "filter[productStatistics.product_id]": this.product_id,
          // "filter_product_expected_delivery[start_date]": this.dateRange?.start,
          // "filter_product_expected_delivery[end_date]": this.dateRange?.end,
        })
      );
    },
    selectProduct(id) {
      this.product_id = id;
    },
    input(id) {
      if (!id) {
        this.product_id = id;
      }
    },
    clearFilter() {
      this.product_id = "";
      this.dateRange = {
        start: null,
        end: null,
      };
      this.lowestPrice = false;
    },
  },
};
</script>
