<template>
  <div>
    <CCard>
      <CCardHeader class="d-flex align-items-center">
        <TMessage content="Create purchase" bold />
        <SButtonCreateOrder
          :options="{ disabled: !check || creating }"
          class="ml-auto"
          @click="create"
        />
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol col="12" md="6" class="mb-3">
            <TInputText
              label="Supplier"
              :value.sync="supplier.name"
              :inputOptions="{ readOnly: true }"
              class="mb-3"
            />
            <TInputText
              label="Address"
              :value.sync="supplier.address"
              :inputOptions="{ readOnly: true }"
              class="mb-3"
            />
            <TInputText
              label="Email"
              :value.sync="supplier.email"
              :inputOptions="{ readOnly: true }"
              class="mb-3"
            />
          </CCol>
          <CCol col="12" md="6" class="mb-3">
            <SSelectCurrency
              label="Currency"
              :value.sync="purchase.currency_id"
              :prepend="[]"
              class="mb-3"
            />
            <TInputMoney
              label="Additional cost"
              :value.sync="purchase.additional_cost"
              @update:value="purchase.additional_cost = $event"
              class="font-weight-bold"
              :currency="purchase.currency_id || 'JPY'"
            />
          </CCol>
          <CCol col="12" md="6" class="mb-3"> </CCol>
        </CRow>
      </CCardBody>
    </CCard>

    <CCard>
      <CCardHeader class="d-flex">
        <TMessage content="Product" bold lowercase>
          <template #prefix>
            {{ listToBuy.length }}
          </template>
        </TMessage>
      </CCardHeader>
      <CCardBody style="min-height: 200px">
        <CElementCover
          :opacity="0.8"
          v-if="orderItemLoading || countInventoryLoading"
        >
          <div class="d-flex">
            <TMessage content="Loading" class="mr-2 h3" />
            <CSpinner size="5xl" color="success" />
          </div>
        </CElementCover>
        <div v-if="!orderItemLoading && !countInventoryLoading">
          <CRow>
            <CCol
              col="12"
              md="6"
              class="overflow-auto"
              style="max-height: 650px; min-height: 250px"
            >
              <TMessage content="Product" size="h4" class="mb-3" />
              <hr />
              <ProductItem
                v-for="(item, index) in listToBuy"
                :key="`${item.product_id} - ${index}`"
                :item="item"
                :selected="item.product_id == selectedItem"
                class="p-0"
                :currency="purchase.currency_id"
                @click.native="selectItem(item.product_id)"
              />
            </CCol>
            <CCol col="12" md="6">
              <TMessage content="Orders" size="h4" class="mb-3" />
              <hr />
              <TSpinner v-if="!check" :loading="orderItemLoading" />
              <div v-else>
                <TMessageNoData
                  v-if="listToBuy[itemIndexSelected].orderItems == 0"
                  content="No order"
                />
                <div
                  v-for="orderItem in [
                    ...listToBuy[itemIndexSelected].orderItems,
                  ]"
                  :key="orderItem.id"
                >
                  <OrderItem
                    v-if="orderItem.product_id == selectedItem"
                    :order="orderItem"
                    :currency_id="purchase.currency_id"
                    :product="listToBuy[itemIndexSelected]"
                    :product_id="orderItem.product_id"
                    :selected="orderItem.id == selectedOrder.id"
                    @click.native="selectOrder(orderItem)"
                    @save-item="saveItem"
                    @update:remaining-quantity="updateRemainingQuantityPurchase"
                    :list="
                      listToBuy[itemIndexSelected]
                        .purchase_available_distributions
                    "
                  />
                </div>
              </div>
            </CCol>
          </CRow>
        </div>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import organizationStore from "@/core/services/tomoni/organization.local";
import OrderItem from "./components/OrderItem.vue";
import { mapGetters } from "vuex";
import ProductItem from "./components/ProductItem.vue";
export default {
  components: { OrderItem, ProductItem },
  props: {
    listToBuy: {
      type: Array,
    },
    supplier: {
      type: Object,
    },
    expectedDelivery: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      purchase: {
        additional_cost: 0,
        currency_id: "JPY",
      },
      selectedOrder: {},
      selectedItem: "",
      orderItems: [],
      distributed: [],
      orderItemLoading: true,
      countInventoryLoading: false,
      showModal: false,
    };
  },
  created() {},
  watch: {
    listToBuy: {
      immediate: true,
      handler(list) {
        if (list.length == 0 && this.$route.query.tab == 1) {
          this.$router.push({ query: { tab: 0 } });
        }
        this.fetchListToBuy();
      },
    },
    selectedItem() {
      this.selectedOrder = {};
    },
    "$route.query.tab"() {
      this.selectedItem = "";
    },
  },
  computed: {
    itemIndexSelected() {
      return this.listToBuy.findIndex((x) => x.product_id == this.selectedItem);
    },
    check() {
      return !this.orderItemLoading && !!this.selectedItem;
    },
    ...mapGetters({
      creating: "order.purchases.creating",
    }),
  },
  methods: {
    getOrderItems(list) {
      if (!this.lodash.isEmpty(this.lodash.pickBy(this.expectedDelivery))) {
        return list.filter(
          (x) =>
            this.expectedDelivery.start <= x.expected_delivery &&
            x.expected_delivery <= this.expectedDelivery.end
        );
      } else {
        return list || [];
      }
    },
    fetchListToBuy() {
      if (this.listToBuy.length > 0) {
        const listProductId = this.listToBuy.map((item) => item.product_id);
        this.$tomoni.order.order_items
          .all({
            "filter[product_id]": listProductId.toString(),
            "filter[available_for_distribution]": 1,
            "filter[order.sales_organization_id]": organizationStore.get(),
            include: "distributions,order,customer",
          })
          .then(({ data }) => {
            this.listToBuy.forEach((x, i) => {
              const orderItems = data.data
                .filter((y) => y.product_id == x.product_id)
                .map((y) => {
                  return {
                    ...y,
                    quantity_from_supplier: 0,
                    quantity_from_inventory: 0,
                    supply_sale_price: 0,
                    distribute_inventory: [],
                    purchase_available_distributions: [],
                  };
                });

              this.listToBuy[i].orderItems = this.getOrderItems(orderItems);
            });
            this.orderItemLoading = false;
          })
          .catch((error) => {
            this.$store.dispatch("errors.push-http-error", error);
          });
        listProductId.forEach((productId) => {
          const indexOrder = this.listToBuy.findIndex(
            (x) => x.product_id == productId
          );
          this.$tomoni.order.purchase_items
            .all({
              "filter[product_id]": productId,
              "filter[available_for_distribution]": 1,
              include: "distributions",
            })
            .then(({ data }) => {
              this.listToBuy[indexOrder].purchase_available_distributions =
                data.data;
            })
            .catch((error) => {
              this.$store.dispatch("errors.push-http-error", error);
            });
          // Fetch actual inventory
          // this.countInventoryLoading = true;
          // this.$tomoni.warehouse.count_product_inventory
          //   .count(productId)
          //   .then((data) => {
          //     this.listToBuy[indexOrder].quantity_in_stock = data.data;
          //     this.countInventoryLoading = false;
          //   });
        });
      }
    },
    selectItem(id) {
      if (id != this.selectedItem) {
        this.selectedItem = id;
      }
    },
    selectOrder(order) {
      if (order.id != this.selectedOrder.id) {
        this.selectedOrder = order;
      }
    },
    saveItem(data) {
      const itemIndex = this.listToBuy[
        this.itemIndexSelected
      ].orderItems.findIndex((x) => x.order_id == data.order_id);

      // Cập nhật số lượng phân hàng từ NCC
      this.listToBuy[this.itemIndexSelected].orderItems[
        itemIndex
      ].quantity_from_supplier = data.quantity_from_supplier;

      // Cập nhật giá bán cho sale
      this.listToBuy[this.itemIndexSelected].orderItems[
        itemIndex
      ].supply_sale_price = data.supply_sale_price;

      // Cập nhật số lượng phân hàng từ tồn kho
      this.listToBuy[this.itemIndexSelected].orderItems[
        itemIndex
      ].quantity_from_inventory = data.quantity_from_inventory;

      // Danh sách đơn hàng phân hàng từ TKM
      this.listToBuy[this.itemIndexSelected].orderItems[
        itemIndex
      ].distribute_inventory = data.distribute_inventory;

      // Cập nhật số lượng mua hàng
      const totalQuantity = this.lodash.sumBy(
        this.listToBuy[this.itemIndexSelected].orderItems,
        "quantity_from_supplier"
      );
      const quantity_for_sale =
        this.listToBuy[this.itemIndexSelected].quantity_for_sale;
      this.listToBuy[this.itemIndexSelected].quantity_for_sale =
        quantity_for_sale > totalQuantity ? quantity_for_sale : totalQuantity;

      // Danh sách các đơn bán đã phân hàng
      if (data.distributed)
        this.distributed = this.lodash.uniq([
          ...this.distributed,
          ...data.distributed,
        ]);
    },
    updateRemainingQuantityPurchase(list, data) {
      // Cập nhật số lượng tồn kho của đơn mua
      const itemIndex = this.listToBuy.findIndex(
        (x) => x.product_id == data.product_id
      );
      list.forEach((x) => {
        const index = this.listToBuy[
          itemIndex
        ].purchase_available_distributions.findIndex(
          (y) => y.purchase_id == x.purchase_id
        );
        const remainingDistributedQuantity =
          this.listToBuy[itemIndex].purchase_available_distributions[index]
            .remaining_distributed_quantity;
        if (data.increase) {
          this.listToBuy[itemIndex].purchase_available_distributions[
            index
          ].remaining_distributed_quantity =
            remainingDistributedQuantity - x.quantity;
        } else {
          this.listToBuy[itemIndex].purchase_available_distributions[
            index
          ].remaining_distributed_quantity =
            remainingDistributedQuantity + x.quantity;
        }
      });
    },
    create() {
      let data = {
        supplier_id: this.supplier.id,
        ...this.purchase,
      };
      let distributionsFromInventory = [];
      const listItem = [];
      this.listToBuy.forEach((x) => {
        let item = {
          product_id: x.product_id,
          quantity: x.quantity_for_sale,
          price: (+x.pivot.price / (100 + x.tax_percent)) * 100,
          tax_percent: x.tax_percent,
        };
        // Phân hàng
        const distributions = [];
        if (x.orderItems.length > 0) {
          x.orderItems.forEach((y) => {
            const item = {
              order_item_id: y.id,
              quantity: y.quantity_from_supplier,
              price: y.supply_sale_price,
            };
            distributions.push(item);
          });
        }
        if (!this.lodash.isEmpty(distributions)) {
          item.distributions = distributions.filter((x) => x.quantity > 0);
        }
        // List phân hàng từ tồn kho mua
        if (x.orderItems.length > 0) {
          x.orderItems.forEach((orderItem) => {
            orderItem.distribute_inventory.forEach((distributeItem) => {
              const item = {
                order_item_id: orderItem.id,
                quantity: distributeItem.quantity,
                price: distributeItem.price,
                purchase_item_id: distributeItem.purchase_item_id,
              };
              distributionsFromInventory.push(item);
            });
          });
        }
        // Tổng số lượng mua hàng
        const totalQuantitySupplier = this.lodash.sumBy(
          x.orderItems,
          "quantity_from_supplier"
        );
        item.quantity =
          x.quantity_for_sale > totalQuantitySupplier
            ? x.quantity_for_sale
            : totalQuantitySupplier;
        listItem.push(item);
      });

      // format data
      listItem.forEach((x, itemIndex) => {
        let obj = this.lodash.omit(x, ["distributions"]);
        const item = this.lodash.mapKeys(obj, (value, key) => {
          return `items[${itemIndex}][${key}]`;
        });
        data = { ...data, ...item };
        if (x.distributions) {
          x.distributions.forEach((object, distributeItemIndex) => {
            const distributionItem = this.lodash.mapKeys(
              object,
              (value, key) => {
                return `items[${itemIndex}][distributions][${distributeItemIndex}][${key}]`;
              }
            );
            data = { ...data, ...distributionItem };
          });
        }
      });
      if (listItem.every((x) => x.distributions.length)) {
        // Tạo đơn hàng
        this.$store
          .dispatch("order.purchases.create", data)
          .then((purchase) => {
            // Nếu có phân hàng từ TKM
            if (distributionsFromInventory.length > 0) {
              const list = distributionsFromInventory.filter(
                (x) => x.quantity > 0
              );
              list.forEach((item) => {
                // Kiểm tra có tồn tại trog đơn mua?
                const distributed = this.distributed.find(
                  (x) =>
                    x.order_item_id == item.order_item_id &&
                    x.purchase_item_id == item.purchase_item_id
                );
                // => Cập nhật phân hàng với SL mới
                if (distributed) {
                  this.$store.dispatch("order.distributions.update", {
                    id: distributed.id,
                    attributes: {
                      quantity: distributed.quantity + item.quantity,
                    },
                  });
                }
                // Chưa có => Tạo phân hàng mới
                else {
                  this.$store.dispatch("order.distributions.create", item);
                }
              });
            }
            this.$router.push({ path: `/purchase/purchases/${purchase.id}` });
          });
      } else {
        this.$store.commit("toasts.push", {
          message: "Have not entered the purchase quantity for the order",
          type: "warning",
        });
      }
    },
  },
};
</script>
